import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

import forBizHeroImg from '../images/hero-woman-with-glasses.jpg'
import forBizSvg from '../images/icon-question.svg'
import chartSideBySide from '../images/chart-sideby-covid.jpg'

const FaqPage = () => (
  <Layout>
    <PageHelmet
      title="FAQ"
      description="We know that many people have questions and concerns during this uncertain time. So we're developing a list of free resources and information for our community."
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Your'}
      accentedTitle={'FAQs'}
      subtitle={"We're here to answer your questions"}
    />
    <section className="section" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-9 center-block">
            <div className="columns anchor-border faq-nav">
              <div className="column">
                <h5>JUMP TO:</h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#masks">Masks</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#hygiene">Hygiene</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#health">Health</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#covid-19">COVID-19</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#benefits">Benefits</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#join">Join</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#general">General</a>
                </h5>
              </div>
            </div>
            <p>
              We know that many people have questions and concerns during this
              uncertain time. So we're developing a list of free resources and
              information for our community.
            </p>
            <a className="anchor" name="masks">
              <h2>Masks</h2>
            </a>

            <h5>Updated CDC Guidelines for 2021</h5>
            <div className="content">
              <p>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/your-health/effective-masks.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b>New Article:</b> Improve How Your Mask Protects You
                  </a>{' '}
                  - CDC, Feb 2021
              </p>
              <p>
                  <a
                    href="https://www.cdc.gov/mmwr/volumes/70/wr/mm7007e1.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b>New Article:</b> Maximizing Fit for Cloth and Medical Procedure Masks to Improve Performance and Reduce SARS-CoV-2 Transmission and Exposure
                  </a>{' '}
                  - CDC, Feb 2021
              </p>
            </div>

            <h5>What do you mean by "Masks worn by all employees"?</h5>
            <div className="content">
              <p>COVID-19 is often spread by infected people directly to others (within 6 feet) or indirectly contaminating surfaces. You can get the virus by coming into contact with an infected person's respiratory droplets when they cough, sneeze, or talk. Asymptomatic or pre-symptomatic people do not feel or show symptoms while infected and spread the virus.</p>
              <p>For clarity, we are recommending methods aligned with the CDC to mitigate the spread of disease.
                <ul>
                  <li>Masks to be worn in all public/ shared work spaces at all times except while eating or drinking in designated areas.</li>
                  <li>It is up to businesses if individuals take off their masks when alone in their private work area with attention to proper personal/ surface/ distancing hygiene. </li>
                  <li>It is up to businesses whether a meeting in a private office with proper physical distancing may occur without masks.  Awareness that there is potential that droplets emitted by breathing/ talking may fall on surfaces around an individual and potentially contaminate surfaces, food, and objects.</li>
                  <li>If an individual is working in a customer’s home/ business then a mask should be worn the entire time</li>
                  <li>Masks or cloth face coverings should cover the nose and mouth and fit comfortably to reduce the need for adjustment.</li>
                  <li>Ability to sanitize hands if the mask or face is touched.</li>
                  <li>Delivery drivers should wear a mask at any time there is the potential to contaminate packages/ food containers (i.e.picking up, delivering, or if relevant driving while safety maintained)</li>
                </ul>
              </p>
              <p>Ultimately, we formally recommend you follow national and local safety standards relevant to your business.</p>

            </div>

            <h5>Do I have to wear a mask when I'm in the office by myself?</h5>
            <div className="content">
              <p>
                No. You should just wear a mask when you're in a shared workspace where you may infect someone else.
              </p>
            </div>

            <h5>Why do people wear masks?</h5>
            <div className="content">
              <p>
                Currently the CDC recommends a medical or cloth mask for decreasing the chance of spreading the virus causing COVID-19 (Source Control).
              </p>
            </div>

            <h5>Is a shield alone OK without a mask?</h5>
            <div className="content">
              <p>
                The CDC does NOT recommend a face shield alone to minimize spread of coronavirus. A shield alone without a mask does not prevent droplets emitted with each breath from falling on surfaces, food, people, and objects and potentially spread to other individuals.
              </p>
            </div>

            <h5>Is a shield with a mask better than a mask alone?</h5>
            <div className="content">
              <p>
                There is indication that a shield with a mask may be better for you and others.
              </p>
            </div>

            <h5>Does the mask need to cover my nose?</h5>
            <div className="content">
              <p>
                Masks or cloth face coverings should cover the nose and mouth and fit comfortably to reduce the need for adjustment.
              </p>
            </div>

            <h5>Do I need to wash my mask?</h5>
            <div className="content">
              <p>
                Yes.  If your respiratory droplets have the coronavirus in them then your mask may be a source for infection
              </p>
            </div>

            <h5>How can I make it so I do not fog my glasses so much?</h5>
            <div className="content">
              <p>
                A moldable wire such as the wire on the top of the bag of your coffee beans/ grounds can be attached to the top of the mask to create a better seal. In addition, some people will wash their glasses with soap and water and gently pat the glasses dry so some soap remnant remains on the glasses which can decrease fogging.  Lastly, some people will use tape on the mask to decrease the moisture coming up on your glasses.
              </p>
            </div>

            <h5>What is the best fitting mask?</h5>
            <div className="content">
              <p>
                <ul>
                  <li>Fit snugly but comfortably along the sides of the face.</li>
                  <li>Be secured with ties or ear loop- do not want to be fixing the mask and touching the face repeatedly</li>
                  <li>Cover mouth and nose with no gaps between face and the mask</li>
                  <li>Include multiple layers of fabric</li>
                  <li>Allow for breathing without restriction</li>
                  <li>Be able to laundered or machine dried without damage or change in shape</li>
                  <li>Allows for the breath to go through the mask and not around it.</li>
                </ul>
              </p>
            </div>

            <h5>What if I cannot afford a mask or find one?</h5>
            <div className="content">
              <p>
                The CDC has <a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html" target="_blank">guidelines for making a mask at home</a>
              </p>
            </div>

            <h5>Why do some people say it may be more dangerous to wear a mask?</h5>
            <div className="content">
              <p>
                A poorly fitting mask may cause one to frequently touch their face or mask and potentially increase the chance of spreading the virus. Please see recommendations for a properly fitting mask.
              </p>
            </div>

            <h5>Does a cloth mask help protect me?</h5>
            <div className="content">
              <p>
                This concept is debatable.  The main purpose of wearing a mask is to decrease the chance that you may infect others.  Some cloth masks with a HEPA filter and a proper seal may pass the official N95 testing process and may be beneficial. A mask alone does not protect the eyes and this a potential source for getting the virus causing COVID-19.
              </p>
            </div>

            <h5>I have a mask with a ‘pop off’ valve, can I spread germs through this valve?</h5>
            <div className="content">
              <p>
                Yes. We recommend that a piece of tape or other method to seal this valve is used so your breath does not escape through the valve.
              </p>
            </div>

            <h5>Do you have other ideas on an ideal mask?</h5>
            <div className="content">
              <p>
                Yes. A mask that has more surface area in front of the mouth will decrease the chance the breath will go around the mask.  One method some use is to utilize the wire from a coffee bag to create a bulge in front of the mouth so your breath has more fabric to go through which may decrease the chance the breath may go around the mask.
              </p>
            </div>

            <h5>If I am sick can I go around others if I wear a cloth mask?</h5>
            <div className="content">
              <p>
                We recommend you follow CDC guidelines and stay home and isolate yourself from others per current recommendations.
              </p>
            </div>

            <h5>Video: Stay Healthy</h5>
            <div className="content">
              <p>
                Watch how droplets spray from the simple phrase <a href="https://www.youtube.com/watch?v=aTTU7o1kzYc" target="_blank">"Stay Healthy"</a> without a mask.
              </p>
            </div>

            <a className="anchor" name="hygiene">
              <h2>Hygiene</h2>
            </a>
            <h5>What do you mean by "Personal/Surface/Distance Hygiene"?</h5>
            <div className="content">
              <p>
                <ul>
                  <li><strong>Personal Hygiene</strong> - The best way to protect yourself and others from germs is to regularly wash your hands with soap and water for 20 seconds or use hand sanitizer with at least 60% alcohol.  In addition, the hands should be sanitized promptly if one touches a potentially contaminated surface or your face.</li>
                  <li><strong>Surface Hygiene</strong> - Practice routine cleaning of frequently-touched surfaces. The ‘Businesses’ section provides excellent resources pertaining to safe and effective methods of cleaning and disinfecting. If working in another’s home or business, methods should be established to sanitize potentially contaminated areas prior to leaving.</li>
                  <li><strong>Distancing Hygiene</strong> -  When possible, It’s important to physically distance yourself from employees and patrons. Maintain at least 6 feet of distance (about two arms’ length) from others if possible to decrease the chance of spread. Consider strategies in your work environment to help with this endeavor, i.e. stagger work shifts & breaks, avoid in-person meetings and large groups, and avoid non-essential travel.</li>
                </ul>
              </p>
              <p>Ultimately, we formally recommend you follow national and local safety standards relevant to your business.</p>
            </div>
            <h5>Resources</h5>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/community/disinfecting-building-facility-H.pdf"
                    target="_blank"
                  >
                    PDF: Disinfecting a Building Facility
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www.epa.gov/pesticide-registration/list-n-disinfectants-use-against-sars-cov-2"
                    target="_blank"
                  >
                    Article: List of Disinfectants to Use for COVID
                  </a>{' '}
                  - EPA
                </li>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fprepare%2Fcleaning-disinfection.html"
                    target="_blank"
                  >
                    Article: Cleaning Disinfection
                  </a>{' '}
                  - CDC
                </li>
              </ul>
            </div>
            <br />

            <a className="anchor" name="health">
              <h2>Health</h2>
            </a>
            <h5>What do you mean by "Daily health self-certification"?</h5>
            <div className="content">
              <p>
                This step is intended to decrease the likelihood of spreading the virus in your work environment by keeping sick individuals out of the workplace. The list of possible COVID-19 symptoms is extensive and will need to be reassessed periodically. The ‘Mask of Wellness’ downloadable kit contains an example of a  COVID-19 self-screening form to help. This daily health self-certification has a list of primary symptoms associated with COVID-19. If an employee  presents to work with related symptom(s) or develops symptom(s) while at work they should leave the workplace immediately and notify a manager. Ideally, the list of symptoms should be known by all employees and not present to work if symptoms are present and notify a manager.  Leave policies should be flexible and staying home while exhibiting symptoms should come without punishment. Ideally, temperature should be taken before or at the beginning of the work day.  If clinical concern is raised by a healthcare professional of COVID-19 then a manager should be notified with recommendation of notifying potential contacts. Ultimately, we formally recommend you follow national and local safety standards relevant to your business.
              </p>
            </div>
            <h5>Resources</h5>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/guidance-small-business.pdf"
                    target="_blank"
                  >
                    PDF: Guidance for Small Businesses
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www1.nyc.gov/assets/doh/downloads/pdf/imm/novel-coronavirus-faq-for-businesses.pdf"
                    target="_blank"
                  >
                    PDF: Coronavirus FAQ for Businesses
                  </a>{' '}
                  - nyc.gov
                </li>
                <li>
                  <a
                    href="https://www.osha.gov/Publications/OSHA3990.pdf"
                    target="_blank"
                  >
                    PDF: Guidance for Preparing Workplaces for COVID-19
                  </a>{' '}
                  - OSHA
                </li>
              </ul>
            </div>

            <a className="anchor" name="covid-19">
              <h2>COVID-19</h2>
            </a>
            <h5>Can I spread the virus causing COVID-19 and not feel sick?</h5>
            <div className="content">
              <p>
                Yes! Up to 40% of the time people spread the virus without symptoms- Asymptomatic and Pre-Symptomatic.  COVID-19 is often spread by infected people in close proximity to others (about six feet). You can get the virus by coming into contact with an infected person's respiratory droplets when they cough, sneeze, breath, or talk.
              </p>
            </div>

            <h5>Can I get the virus by touching my eyes?</h5>
            <div className="content">
              <p>
                Yes. The virus can get into your tears which drain into your nose and respiratory tract.
              </p>
            </div>

            <a className="anchor" name="benefits">
              <h2>Benefits</h2>
            </a>
            <h5>Of Joining The Community</h5>
            <div className="content">
              <ul>
                <li>
                  The virus often spreads by people who have No symptoms-in Your
                  workplace and potentially to your customers.
                </li>
                <li>
                  A Mask reduces spread by ‘asymptomatic’ and ‘pre-symptomatic’
                  people. We don’t want the silent threat infecting anyone in
                  OUR families.
                </li>
                <li>
                  Consumers often have fear and will select businesses that are
                  ‘Covid-Conscious’
                </li>
                <li>
                  It will improve the physical, emotional, social, and economic
                  well-being of our communities- City or Rural
                </li>
                <li>
                  Society Decides the the course of disease- Lets all do our
                  part.
                </li>
                <li>
                  The virus causing COVID likes to spread to others before
                  symptoms, a major reason this will be long lasting unless we
                  take COVID-Conscious action steps.
                </li>
                <li>
                  We All Will improve the physical and economic well-being of
                  our communities.
                </li>
                <li>This will be temporary</li>
                <li>
                  We must open society in a manner that promotes wellness;
                  socially, physically, and economically.
                </li>
              </ul>
            </div>

            <a className="anchor" name="join">
              <h2>Join</h2>
            </a>
            <h5>But I already follow These Requirements</h5>
            <div className="content">
              <p>
                Thank you!! Now influence those who look up to you by signing up
                and spreading the word instead of ‘spraying’ the word. We
                appreciate your help with advancing this endeavor that benefits
                the Health and Wealth of our Community.
              </p>
            </div>

            <h5>How Long Do We Follow These Requirements?</h5>
            <div className="content">
              <p>
                Just until CDC provides recommendations that mask wearing is no
                longer needed (found at{' '}
                <a href="https://www.cdc.gov">cdc.gov</a>) or you choose to
                stop. When you choose to no longer follow the guidelines, remove any signage and notify us.
              </p>
            </div>

            <h5>How Do I Join?</h5>
            <div className="content">
              <p>
                Businesses can register as a 'Covid-Conscious' business. Consumers can read
                steps they can take below.
              </p>
              <Link to="/for-businesses">
                <button className="button">For Businesses</button>
              </Link>
              <Link to="/for-consumers">
                <button className="button">For Consumers</button>
              </Link>
            </div>

            <h5>How Else Can I Support The Mask of Wellness Community</h5>
            <div className="content">
              <p>
                Businesses can register as a 'Covid-Conscious' business. Consumers can read
                steps they can take below.
              </p>
              <Link to="/how-to-help">
                <button className="button">Donate</button>
              </Link>
              <Link to="/products">
                <button className="button">Buy Products</button>
              </Link>
            </div>

            <a className="anchor" name="general">
              <h2>General</h2>
            </a>
            <h5>What if I have additional questions?</h5>
            <div className="content">
              <p>
                Seek guidance from the CDC, OSHA, or your regulatory agency.  Also, send them to us and we may post them here so we may help others.
              </p>
            </div>

            <h5>What if I have a underlying medical condition, should I wear a mask?</h5>
            <div className="content">
              <p>
                Seek guidance from your medical provider.
              </p>
            </div>

            <h5>What if I have a medical emergency?</h5>
            <div className="content">
              <p>
                Call 911.
              </p>
            </div>

            <h5>What if I have a skin condition?</h5>
            <div className="content">
              <p>
                Seek guidance from your medical professional.
              </p>
            </div>

          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default FaqPage
